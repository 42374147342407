// Grid size
$grid-max-width: 100rem;


// Spaces
$section-gutter: 10rem;


// Typography
$base-font-size: 16px;
$base-line-height: 1.5;

$base-paragraph-size: 1rem;

$hero-font-size: 4rem;
$h1-font-size: 80px;
$h2-font-size: 2rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;

$underlined-links: true;


// Fonts
$fonts-path: '../../../common/fonts';

$font-optima: "Optima CE", Arial;
$font-noto: 'Noto Serif Display', serif;

// Images
$images-path: '../../images';


// Colors

$red: #C61623;
$white: #FFF;
$black: #000;

$gold: #CEA44A;
$light: #EABB66;
$light-gold: #C39836;
$brown: #40281C;
$lightbrown: #E9B860;

$base-text-color: $gold;


// Forms
$form-group-gutter: 2rem;

$form-input-padding-tops: 0.8rem;
$form-input-padding-sides: 1rem;

$form-input-border-radius: 0;
$form-input-border-width: 2px;
$form-input-border-color: #CEA44A;

$form-input-text-size: $base-font-size;

$form-input-background-color: #1F1500;
$form-input-text-color: rgba(255, 255, 255, 1);


// Common radius
$base-radius: 0.5rem;
$button-radius: 0.5rem;
