@import '../config';
@import '../core/breakpoints';

// Display and position definitions
// --------------------------------
.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}

.block {
	display: block;
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

.table {
	display: table;
}

.hidden {
	display: none;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}
// --------------------------------

// Text aligns
// --------------------------------
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}
// --------------------------------

// Animations
// --------------------------------
.trans {
	transition: all 300ms ease-in-out;
}
// --------------------------------

// Text sizes
// --------------------------------
.text-large {
	font-size: 125%;
}

.text-small {
	font-size: 75%;
}
// --------------------------------


// Margins
// --------------------------------
.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem !important;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-4 {
	margin-top: 4rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-4 {
	margin-bottom: 4rem;
}

.mb-5 {
	margin-bottom: 5rem;
}

.gold {
	color: $gold;
}

.dark-gold {
	color: #CEA44A;
}


.goldLine {
	position: relative;
	display: block;
	background-color: $gold;
	width: 100%;
	height: 3px;
	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		top: 5px;
		height: 5px;
		background-color: $gold;
	}
}

.goldLine--invert {
	&:after {
		top: -8px;
	}
}

.desktop-only {
	display: block;
	@include max1024px {
		display: none;
	}
}

.mobile-only {
	display: none;

	@include max1024px {
		display: block;
	}
}
